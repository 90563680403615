import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const WoWTypography = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", sans-serif',
  color: '#ffd700',
  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
}));

const WoWCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #2c3e50, #34495e)',
  borderRadius: '10px',
  overflow: 'hidden',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 10px 20px rgba(0,0,0,0.4)',
  },
  height: '100%',
  textDecoration: 'none', // Remove underline
}));

const WoWCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 0,
  paddingTop: '56.25%', // 16:9 aspect ratio
}));

const WoWCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", sans-serif',
  color: '#ffd700',
  textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
  fontSize: '2rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(4),
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButton-root': {
    color: '#ffd700',
    border: '1px solid #ffd700',
    '&.Mui-selected': {
      backgroundColor: 'rgba(255, 215, 0, 0.2)',
      color: '#ffd700',
    },
  },
}));

const Home = () => {
  const [drVariant, setDrVariant] = useState('normal');

  const handleDrVariantChange = (event, newVariant) => {
    if (newVariant !== null) {
      setDrVariant(newVariant);
    }
  };

  const getDrDescription = () => {
    switch (drVariant) {
      case 'orc':
        return (
          <>
            "Lok'tar ogar! As mighty Orc warriors with <strong>Hardiness</strong>, we have a natural resistance to stuns. Understanding how Diminishing Returns affect stuns can give us an edge in intense arena battles."
          </>
        );
      case 'gnome':
        return (
          <>
            "Gnomeregan forever! As nimble Gnomes with <strong>Escape Artist</strong>, we excel at slipping out of snares. Let's explore how Diminishing Returns impact roots and slows in arenas."
          </>
        );
      default:
        return (
          <>
            "Greetings, adventurer! Mastering Diminishing Returns is crucial for PvP dominance. Let's delve into how DR works with crowd control abilities in realistic arena scenarios."
          </>
        );
    }
  };
  
  const getDrList = () => {
    switch (drVariant) {
      case 'orc':
        return (
          <ul>
            <li>
              <strong>First stun</strong>: 100% duration (reduced by 20% thanks to <strong>Hardiness</strong>).
            </li>
            <li>
              <strong>Second stun within 18 seconds</strong>: 50% duration (Hardiness still applies).
            </li>
            <li>
              <strong>Third stun within 18 seconds</strong>: 25% duration.
            </li>
            <li>
              <strong>Fourth stun within 18 seconds</strong>: Immune to stuns.
            </li>
            <li>DR resets if no stuns are received for 18 seconds.</li>
          </ul>
        );
      case 'gnome':
        return (
          <ul>
            <li>
              <strong>First root/snare</strong>: 100% duration (can be removed with <strong>Escape Artist</strong>).
            </li>
            <li>
              <strong>Second root/snare within 18 seconds</strong>: 50% duration.
            </li>
            <li>
              <strong>Third root/snare within 18 seconds</strong>: 25% duration.
            </li>
            <li>
              <strong>Fourth root/snare within 18 seconds</strong>: Immune to roots/snares.
            </li>
            <li>DR resets if no roots/snares are received for 18 seconds.</li>
          </ul>
        );
      default:
        return (
          <ul>
            <li>
              <strong>First crowd control (e.g., Polymorph)</strong>: Full duration.
            </li>
            <li>
              <strong>Second of the same CC within 18 seconds</strong>: Half duration.
            </li>
            <li>
              <strong>Third of the same CC within 18 seconds</strong>: Quarter duration.
            </li>
            <li>
              <strong>Fourth of the same CC within 18 seconds</strong>: Immune to that CC.
            </li>
            <li>DR resets if no CCs of that category are received for 18 seconds.</li>
          </ul>
        );
    }
  };

  const FloatingButton = styled('div')(({ theme }) => ({
    position: 'fixed',
    left: 0,
    top: '95%',
    transform: 'translateY(-50%)',
    zIndex: 1000,
    // Optional: Hide on small screens
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }));

  return (
    <Box sx={{ padding: 4 }}>
      <FloatingButton>
      <a href="https://www.buymeacoffee.com/amoner"><img src="https://img.buymeacoffee.com/button-api/?text=Buy me a food buff&emoji=🥪&slug=amoner&button_colour=5F7FFF&font_colour=ffffff&font_family=Poppins&outline_colour=000000&coffee_colour=FFDD00" /></a>
      </FloatingButton>
      <WoWTypography variant="h2" align="center" gutterBottom>
        Get your Challenger Title with GladPush.gg
      </WoWTypography>

      <SectionTitle align="center">No rating gain guarantee.</SectionTitle>
      <Typography variant="body1" align="center" gutterBottom sx={{ color: 'white', maxWidth: '800px', margin: '0 auto', mb: 4 }}>
      Friend just rolled yet another flavor-of-the-month alt to dominate PvP? Dealing with a quirky Solo Shuffle team comp? Got roped into 3s and don't want to be the weak link? No worries! Check the DRs on our website and gain the upper hand.
      </Typography>

      <SectionTitle align="center">Our "Powerful" Features</SectionTitle>
      <Grid container spacing={4} justifyContent="center" sx={{ border: 'none' }}>
        <Grid item xs={12} sm={6} md={4}>
          <WoWCard component={RouterLink} to="/dr-matrix">
            <WoWCardMedia
              image="/mage_matrix.png"
              title="DR Matrix"
            />
            <WoWCardContent>
              <Typography variant="h6" component="div" sx={{ color: '#ffd700', marginBottom: 1 }}>
                DR Team Analyzer
              </Typography>
              <Typography variant="body2" sx={{ color: 'white' }}>
                Visualize and optimize your team's Diminishing Returns for maximum CC efficiency.
              </Typography>
            </WoWCardContent>
          </WoWCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <WoWCard component={RouterLink} to="/dr-training">
            <WoWCardMedia
              image="/orcs_game.png"
              title="DR Game"
            />
            <WoWCardContent>
              <Typography variant="h6" component="div" sx={{ color: '#ffd700', marginBottom: 1 }}>
                DR Knowledge Check
              </Typography>
              <Typography variant="body2" sx={{ color: 'white' }}>
                Sharpen your DR knowledge with our interactive training game.
              </Typography>
            </WoWCardContent>
          </WoWCard>
        </Grid>
      </Grid>

      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button
          component={RouterLink}
          to="/dr-matrix"
          variant="contained"
          size="large"
          sx={{
            backgroundColor: '#ffd700',
            color: '#2c3e50',
            '&:hover': {
              backgroundColor: '#e6c200',
            },
          }}
        >
          Start Analyzing Your Team
        </Button>
      </Box>

      <SectionTitle align="center">Understanding Diminishing Returns (DR)</SectionTitle>
<Paper
  elevation={3}
  sx={{
    padding: 3,
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: 'white',
    maxWidth: '800px',
    margin: '0 auto',
  }}
>
  <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
    <StyledToggleButtonGroup
      value={drVariant}
      exclusive
      onChange={handleDrVariantChange}
      aria-label="DR variant"
    >
      <ToggleButton value="normal" aria-label="normal">
        Normal
      </ToggleButton>
      <ToggleButton value="orc" aria-label="orc">
        Orc
      </ToggleButton>
      <ToggleButton value="gnome" aria-label="gnome">
        Gnome
      </ToggleButton>
    </StyledToggleButtonGroup>
  </Box>
  <Typography variant="body1" paragraph>
    {getDrDescription()}
  </Typography>
  {getDrList()}
  <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>Example Scenario:</Typography>
  <Typography variant="body1" paragraph>
    In a 3v3 arena match, your team (Warrior, Mage, Druid) coordinates a CC chain on the enemy healer:
  </Typography>
  <ol>
    <li>Druid uses <strong>Mighty Bash</strong> (stun) - full duration</li>
    <li>Mage casts <strong>Polymorph</strong> - full duration (different DR category)</li>
    <li>Warrior uses <strong>Storm Bolt</strong> (stun) - 50% duration due to DR</li>
  </ol>
  <Typography variant="body1" sx={{ mt: 2 }}>
    Master DR timings to maximize your team's control and climb the arena ladder!
  </Typography>
</Paper>

<SectionTitle align="center" sx={{ mt: 4 }}>What Players Say</SectionTitle>
<Box sx={{ maxWidth: '600px', margin: '0 auto', textAlign: 'center' }}>
  <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'white' }}>
    "GladPush.gg has been a game-changer for our arena team. The DR Analyzer helped us optimize our CC chains and climb to Gladiator rank!"
  </Typography>
  <Typography variant="subtitle1" sx={{ color: '#ffd700', mt: 2 }}>
    - Zug'zug, Orc Warrior, Gladiator
  </Typography>

  {/* New testimonial from a dad age 34 */}
  <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'white', mt: 4 }}>
    "As a busy dad balancing work and family, I don't have time to keep up with all the PvP changes. GladPush.gg made understanding DR mechanics simple, helping me improve without the grind."
  </Typography>
  <Typography variant="subtitle1" sx={{ color: '#ffd700', mt: 2 }}>
    - Thoren, 34, Night Elf Druid
  </Typography>

  {/* New testimonial from a 15-year-old */}
  <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'white', mt: 4 }}>
    "I'm new to arenas and was getting wrecked all the time. The DR Knowledge Check game on GladPush.gg made learning fun, and now I'm holding my own in PvP!"
  </Typography>
  <Typography variant="subtitle1" sx={{ color: '#ffd700', mt: 2 }}>
    - Kira, 15, Blood Elf Mage
  </Typography>

  {/* New testimonial from a forever duelist */}
  <Typography variant="body1" sx={{ fontStyle: 'italic', color: 'white', mt: 4 }}>
    "I've been stuck at Duelist rank for ages. With the DR Team Analyzer, I finally figured out where our CC rotations were failing. We're now pushing higher ratings (But still within Duelist range!)!"
  </Typography>
  <Typography variant="subtitle1" sx={{ color: '#ffd700', mt: 2 }}>
    - Grimgar, Dwarf Hunter, Forever a Duelist
  </Typography>
</Box>
    </Box>
  );
};

export default Home;